<template>
  <router-view />
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'App',
    ...mapActions({
      setAuth   : 'auth/set',
    }),
    computed: {
      ...mapGetters({
        user : 'auth/user',
      }),
    },
    methods: {
    },
  };
</script>

<style src="./styles/app.scss" lang="scss"/>


