<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">Daftar Pinjaman</h1>
        <!-- <v-col cols=12>
            <v-radio-group
            v-model="row"
            row
            >
            <v-radio
                label="Belum Lunas"
                value="5"
            ></v-radio>
            <v-radio
                label="Lunas"
                value="6"
            ></v-radio>
            </v-radio-group>
        </v-col> -->
    </v-row>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Aksi</th>
                        <th class="text-center">No</th>         
                        <th class="text-center">Jenis</th>               
                        <th class="text-center">No Pinjaman</th>
                        <th class="text-center">Tgl Pinjam</th>
                        <th class="text-right">Total Pinjam</th>
                        <th class="text-right">Jangka Waktu</th>
                        <th class="text-right">Angsuran ke</th>
                        <th class="text-right">Sisa Pinjaman</th>
                        <th class="text-center">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">
                        <td>
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                class="text-capitalize button-shadow mr-1"
                                @click="detail(data.notrs)"
                                >Detail</v-btn>
                        </td>
                        <td>{{ index+1 }}</td>
                        <td>{{ data.nm }}</td>
                        <td>{{ data.notrs }}</td>
                        <td>{{ data.tgl }}</td>
                        <td class="text-right">{{ Number(data.totalpeminjaman).toLocaleString() }}</td>
                        <td class="text-right">{{ data.lamapinjam }}</td>
                        <td class="text-right">{{ data.noangsuran }}</td>
                        <!-- <td class="text-right">{{ (data.sistempinjam=="0" ? "Tetap" : "Menurun") }}</td> -->
                        <td class="text-right">{{ (Number(data.sisapinjaman) + Number(data.saldotambahan)).toLocaleString() }}</td>
                        <td>{{ status(data.statusproses) }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>
    <div class="tables-basic history" v-show="isShowing">
        
        <div v-for="d in dataPinjaman" :key="d.notrs">
            <center><h4  v-if="d.nonpinjaman=='0'" class="">Histori Pembayaran Pinjaman</h4>
                      <h4  v-else  class="">Histori Penambahan Kurang Pot Bank</h4>
            </center>
             
            <v-row>
                <v-col cols="col-12" lg="4">
                    <v-text-field label="Tanggal Pinjam"
                                :value="d.tgl"
                                readonly></v-text-field>
                    <v-text-field label="Total Pinjaman"
                                :value="Number(d.totalpeminjaman).toLocaleString()"
                                readonly></v-text-field>
                    <v-text-field label="Sistem Bunga"  v-if="d.nonpinjaman=='0'"
                                :value='(d.sistempinjam=="0" ? "Tetap" : "Menurun")'
                                readonly></v-text-field>
                </v-col>
                <v-col cols="col-12" lg="4"></v-col>
                <v-col  v-if="d.nonpinjaman=='0'" cols="col-12" lg="4">
                    <v-text-field label="Bunga"
                                :value="d.bungapinjam+' %'"
                                readonly></v-text-field>
                    <v-text-field label="Jangka Waktu (Bln)"
                                :value="d.lamapinjam"
                                readonly></v-text-field>
                    <v-text-field label="Sisa Pinjaman"
                                :value="(Number(d.sisapinjaman) + Number(d.saldotambahan)).toLocaleString()"
                                readonly></v-text-field>
                </v-col>
            </v-row>
        
        <v-card class="mb-1">
        <v-simple-table>
            <thead>
                <tr v-if="d.nonpinjaman=='0'" >
                    <th class="text-center">No</th>
                    <th class="text-left">Tanggal</th>                        
                    <th class="text-right">Pokok</th>
                    <th class="text-right">Bunga</th>
                    <th class="text-right">Denda</th>
                    <th class="text-right">Total Bayar</th>
                    <th class="text-right">Saldo Akhir</th>
                </tr>
                 <tr v-else >
                    <th class="text-left">No</th>
                    <th class="text-left">Tanggal</th>                        
                    <th class="text-right">Tambah</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(data, index) in dataPembayaran" :key="data.pk">
                    <td>{{ index+1 }}</td>
                    <td class="text-left">{{ data.tgl }}</td>
                    <td class="text-right" >{{ Number(data.pokok).toLocaleString() }}</td>
                    <td v-if="d.nonpinjaman=='0'" class="text-right">{{ Number(data.bunga).toLocaleString() }}</td>
                    <td  v-if="d.nonpinjaman=='0'" class="text-right">{{ Number(data.denda).toLocaleString() }}</td>
                    <td v-if="d.nonpinjaman=='0'" class="text-right">{{ Number(data.totalbayar).toLocaleString() }}</td>
                    <td v-if="d.nonpinjaman=='0'" class="text-right">{{ Number(data.saldoakhir).toLocaleString() }}</td>
                </tr>
            </tbody>
        </v-simple-table>

        </v-card>
        </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DaftarPinjaman',
  data () {
    return {
        row: '',
        isShowing:false,
        dataTable:[],
        dataPinjaman: [],
        dataPembayaran: [],
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    detail(notrs){
        let formData = {
            'notrs' : notrs,
            'tgl': new Date().toISOString().substr(0, 10),
        }

        this.axios.post('/listTPeminjamanByNotrs', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPinjaman = data;
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
        this.axios.post('/listHistoryPembayaran', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPembayaran = data;
                this.isShowing = true
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
    },
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      'tgl': new Date().toISOString().substr(0, 10),
      // 'row': this.row ,
    }

    this.axios.post('/listTPeminjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>