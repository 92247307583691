<template>
  <v-container fluid>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">Mutasi Simpanan</h1>
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-card-text>
              <v-form ref="form" lazy-validation> 
                <v-row>
                  <v-col cols="col-12" lg="4">
                    <v-row>
                      <v-col cols="col-12" lg="12">
                        <v-select v-model="selNametabungan"
                          item-text="notabungan"
                          item-value="notabungan"
                          :items="listNamatabungan"
                          label="Pilih Simpanan"
                          prepend-icon="mdi-filter"
                          dense>
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="col-12" lg="4">
                    <v-row>
                      <v-col cols="col-12" lg="6">
                        <v-menu
                          ref="fromDate"
                          v-model="fromDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fromPickedDate"
                              label="Tanggal"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              persistent-hint
                              
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fromPickedDate"
                            @input="fromDate = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="col-12" lg="6">
                        <v-menu
                          ref="toDate"
                          v-model="toDate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="toPickedDate"
                              label="Sampai"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              persistent-hint
                              
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="toPickedDate"
                            @input="toDate = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="col-12" lg="2" class="text-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                          class="text-capitalize button-shadow mr-1"
                          @click="filter"
                          >Filter</v-btn
                        >
                      </template>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-text v-show="tableShow">
              <v-simple-table>
                <template v-slot:default >
                  <thead>
                  <tr>
                    
                    <th class="text-left pa-6">Tanggal</th>
                    <th class="text-left">Keterangan Rek</th>
                    <th class="text-right">Debet</th>
                    <th class="text-right">Kredit</th>
                    <th class="text-right">Saldo Akhir</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>

                      <td class="pa-6">{{ fromPickedDate }}</td>
                       <td class="text-left">Saldo Awal</td>
                      <td class="text-right">0</td>
                      <td class="text-right">0</td>
                      <td class="text-right">{{ Number(Math.round(saldoAwal)).toLocaleString() }}</td>
                      <!-- index+1 == 1 ? saldoAwal : (data.debet == 0 ? saldoAkhir+=data.kredit : saldoAkhir-=data.debet) -->
                    </tr>
                    <tr v-for="data in dataTableSimpanan" :key="data.pk">
                      <td class="pa-6">{{ data.tgl }}</td>
                      <td>{{ data.nm }}</td>
                      <td class="text-right">{{ Number(Math.round(data.debet)).toLocaleString() }}</td>
                      <td class="text-right">{{ Number(Math.round(data.kredit)).toLocaleString() }}</td>
                       <td class="text-right">{{ Number(Math.round(data.saldo)).toLocaleString() }}</td>
                      <!-- <td class="text-right" v-if='(index+1) == 1'>{{ data.debet == 0 ? Number(saldoAkhir=parseInt(saldoAwal)+ parseInt(data.kredit)).toLocaleString() : Number(saldoAkhir=parseInt(saldoAwal)-parseInt(data.debet)).toLocaleString() }}</td>
                      <td class="text-right" v-else>
                        {{ data.debet == 0 ? Number(saldoAkhir=parseInt(saldoAkhir) + parseInt(data.kredit)).toLocaleString() : Number(saldoAkhir-= parseInt(data.debet)).toLocaleString() }}
                      </td> -->



                      <!-- <td class="text-right">{{ Number(data.debet).toLocaleString() }}</td>
                      <td class="text-right">{{ Number(data.kredit).toLocaleString() }}</td>
                      <td class="text-right" v-if='(index+1) == 1'>{{ data.debet == 0 ? Number(saldoAkhir=parseInt(saldoAwal)+ parseInt(data.kredit)).toLocaleString() : Number(saldoAkhir=parseInt(saldoAwal)-parseInt(data.debet)).toLocaleString() }}</td>
                      <td class="text-right" v-else>
                        {{ data.debet == 0 ? Number(saldoAkhir=parseInt(saldoAkhir) + parseInt(data.kredit)).toLocaleString() : Number(saldoAkhir-= parseInt(data.debet)).toLocaleString() }}
                      </td> -->

                       

                      <!-- index+1 == 1 ? saldoAwal : (data.debet == 0 ? saldoAkhir+=data.kredit : saldoAkhir-=data.debet) -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <!-- <v-card-actions v-show="actShow">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="success"
                class="text-capitalize button-shadow mr-1"
                >Cetak</v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MutasiSimpanan',
  data () {
    return {
      tableShow: false,
      actShow: false,
      fromPickedDate: new Date().toISOString().substr(0, 10),
      toPickedDate: new Date().toISOString().substr(0, 10),
      listNamatabungan : [], // data untuk select simpanan
      dataTableSimpanan : [], // data untuk table simpanan
      saldoAwal: 0,
      selNametabungan:'',
      saldoAkhir: 0,
      toDate:'',
      fromDate:'',
      dateFormatted:'',

    }
  },
  mounted: function() {
    this.getNamatabungan();
    // this.filter();
  }, 
  computed: {
      ...mapGetters({
          user : 'auth/user',
      }),
  },
  methods: {
    ...mapActions({
      setAlert : 'alert/set',
      setAuth : 'auth/set',
    }),
    getNamatabungan(){
      let formData = new FormData()
      formData.set('id', this.user.id)

      this.axios.post('/getNamatabungan', formData)
      .then((response) => {
        let { data } = response.data
        this.listNamatabungan = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    },
    filter(){
      let formData = new FormData()
      formData.set('selNametabungan', this.selNametabungan)
      formData.set('fromDate', this.fromPickedDate)
      formData.set('toDate', this.toPickedDate)
       console.log(this.selNametabungan," : ", this.fromPickedDate," : ",this.toPickedDate);
      
      this.axios.post('/getDataTableSimpanan', formData)
      .then((response) => {
        let { data } = response.data;
        this.dataTableSimpanan = data;

        this.tableShow = true
        this.actShow = true
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
      // hitung saldo awal
       this.axios.post('/getSaldoAwal', formData)
      .then((response) => {
        this.saldoAwal = response.data.saldoAwal;
        console.log(response.data.saldoAwal)
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
    }
  },
}

</script>

