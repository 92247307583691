<template>
  <v-container fluid>
    <div class="dashboard-page">
      <h1 class="page-title mt-10 mb-6">Dashboard</h1>
      <v-row>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-line</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{totalsimpanan}}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>TOTAL SIMPANAN</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                  @click="tampilsimpanan"
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-chart-pie</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{sisapinjaman}}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>SISA PINJAMAN</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
        
                  @click="tampilpinjaman"
                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col lg="4" sm="6" cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="col-12" lg="4">
                  <v-icon size="80">mdi-cash</v-icon>
                </v-col>
                <v-col cols="col-12" lg="8">
                    <h6>{{totaltagihan}}&nbsp;<v-icon color="textColor">mdi-download</v-icon></h6>
                    <h5>TAGIHAN BULAN INI</h5>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="6">
                <v-btn
                   @click="kedaftartagihan"

                  color="primary"
                  >Detail</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              
              <div class="text-xl-h4">News</div>
             
              <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            
            <v-data-table
           
              :headers="headers"
              :items="news"
              @click:row="rowClick" item-key="notrs" 
              :search="search"
               
              single-select

              
            >
               
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    
                    class="mr-2"
                     @click="detail(item)"
                  >
                    mdi-tooltip-edit
                  </v-icon>
                  
                </template>
     
            </v-data-table>

            <!-- <v-simple-table>
            <thead>
              <tr>
                <th class="primary--text">
                  Detail
                </th>
                <th class="primary--text">
                  Tanggal
                </th>
                <th class="primary--text">
                  Judul
                </th>
                 <th class="primary--text" hidden>
                  detail
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d) in news" :key="d.notrs">
                <td>
                  <v-btn  @click="detail(d.notrs)" color="primary" fab x-small darkk>
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn> 
                   
                </td>
                <td>{{d.tanggal}}</td>
                <td>{{d.kop}}</td>
                <td hidden>{{d.detail}}</td>
              </tr>
            </tbody>
          </v-simple-table> -->
          
        <v-col cols="auto">
              <v-dialog
                v-model="showdetailberita"
                transition="dialog-top-transition"
                max-width="1000"
              >
                 
                <template v-slot:default="showdetailberita">
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                    >Detail Berita</v-toolbar>
                    <v-card-text>
                       <div>
                         <h1 class="news-title mt-10 mb-6"  > {{kop}} 
                       </h1> </div>
                      <div v-html="isi"> </div>
                  
                    </v-card-text>
                    
                    <v-card-actions class="justify-end">
                       
                      <v-btn
                        text
                        color="warning"
                        @click="showdetailberita.value=false "
                      >Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
        </v-col>

            
          </v-card>
        </v-col>
 

      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import moment from 'moment';

export default {
  name: 'Dashboard',
  data () {
    return {
      selectedId:-1,
       kop:'',
      isi:'',
      judul:'',
      pk:'',
      showdetailberita:false,
      selected:'',
      totalsimpanan : 0,
      sisapinjaman : 0,
      totaltagihan:0,
      editedIndex: -1,
       
      url:'/public/favicon.ico',
       search: '',
        headers: [
          
          { text: 'Actions', value: 'actions', sortable: false },
          { text: 'Tanggal', value: 'tanggal' ,width: "10%" },
          { text: 'Informasi', value: 'kop' ,width: "82%"  },
          // { text: 'pk', value: 'notrs', align: ' d-none' }, 
          // { text: 'Detail', value: 'detail', align: ' d-none' }, 
          // { text: 'Detail', value: 'detail' },
          
          //  {
          //   text: 'Dessert (100g serving)',
          //   align: 'left',
          //   sortable: false,
          //   value: 'name'
          // },
          // { text: 'Calories', value: 'calories' },
          // { text: 'Fat (g)', value: 'fat' },
          // { text: 'Carbs (g)', value: 'carbs' },
          // { text: 'Protein (g)', value: 'protein' },
          // { text: 'Iron (%)', value: 'iron' }
          
        ],
        

        news: [] ,
        
        
    }
    
  }, 
  computed: {
    ...mapGetters({
      guest : 'auth/guest',
      user : 'auth/user',
    }),
    
    

  },
   


  mounted: function() {
     let formData = {
      'id' : this.user.id,
      'tgl': new Date().toISOString().substr(0, 10),
      'bln' : new Date().getMonth() + 1,
      'thn' : new Date().getFullYear()
 

    }

    this.axios.post('/listNews')
    .then((response) => {
      let { data } = response.data
      this.news = data;
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })
    
    this.axios.post('/totalSimpanan', formData)
    .then((response) => {
      let { data } = response.data
      this.totalsimpanan = Number(Math.round(data)).toLocaleString();
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })
    
    this.axios.post('/sisaPinjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.sisapinjaman = Number(Math.round(data)).toLocaleString();
      console.log ('Sisa pinajam ',this.sisapinjaman);
      // this.sisapinjaman = Number(this.sisapinjaman).toLocaleString() ;
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })

    this.axios.post('/totalTagihan', formData)
    .then((response) => {
      let { data } = response.data
      this.totaltagihan = Number(Math.round(data)).toLocaleString();
      
    })
    .catch((error) => {
      let responses = error.response
      console.log(responses.data.message)
    })

  },
  methods: {
    ...mapActions({
        setAuth   : 'auth/set',
        setAlert : 'alert/set',

      
       
        
      

        kedaftartagihan(){
            this.$router.push('/daftartagihan');
        },
        tampilsimpanan(){
          this.$router.push('/DataSimpanan');
        },

        tampilpinjaman(){
          this.$router.push('/DaftarPinjaman');
        },
        

        

        
    }),

    rowClick: function (item) {   
      // console.log(item.kop);
      
      this.detail(item.notrs)
      // row.select(true);
      // this.selectedId=item.notrs
    },
    
    detail(notrs){
          console.log ('pk aaladh ' + notrs);
           let formData = {
            'pk' : notrs,
              }
            this.axios.post('/detailberita', formData)
            .then((response) => {
                    let { data } = response.data
                    this.databerita = data;
                    this.kop=this.databerita[0].kop;
                    this.isi=this.databerita[0].detail;
                })
                .catch((error) => {
                let responses = error.response
                this.setAlert({
                    status : true,
                    color : 'error',
                    text : responses.data.message,
                })
            })
            this.showdetailberita=true;
              
          },
  },
}

</script>

<style src="./Dashboard.scss" lang="scss"/>
 

