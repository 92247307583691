<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">Download Formulir</h1>

    </v-row>
    <v-row>
      

      
            
    </v-row>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">Klik Download</th>
                        <th class="text-left">Formulir</th>         
                        <th class="text-left">Deskripsi</th>               

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data) in dataTable" :key="data.pk">
                        <td>
                            <!-- <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                class="button-shadow mr-1"
                                @click="downloaddata(data.path)"
                              >
                                download</v-btn> -->

                                <v-icon
                    
                                  class="mr-2"
                                  @click="downloaddata(data.path)"
                                >
                                  mdi-tooltip
                                </v-icon>
                                

                             
                        </td>
                        <td>{{data.nm}}</td>
                        <td> {{data.ctn}}  </td>
                         
                        
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>

 
  
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
 
 
 

export default {
  name: 'Formulir',
  data () {
    return {
      url1:'https://www.mediafire.com/file/iua7rq57c0p1qrq/FORM_TAB_PROGRAM.doc/file',
      path:'',
       
      nm:'',
      pk:'',
      ctn:'',

       
      content: '',
        editorOption: {
          // Some Quill options... 
        }    ,
        row: '',
        dataTable:[],
         
    }
  },
   

  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
   
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
     
    

    refreshberita(){
      let formData = {
        'pk': this.notrs,
      }
        this.axios.post('/listDownload', formData)
          .then((response) => {
            let { data } = response.data
            this.dataTable = data;
          })
          .catch((error) => {
            let responses = error.response
            this.setAlert({
              status : true,
              color : 'error',
              text : responses.data.message,
            })
          })
    },

    downloaddata(path){
      window.open(path);
    }
    
  
  
    
    
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      'tgl': new Date().toISOString().substr(0, 10),
      // 'row': this.row ,
    }

    this.axios.post('/listDownload', formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>