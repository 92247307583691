<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">Pengajuan Pinjaman</h1>
        <v-col cols=12>
            <div>Simulasi Pinjaman/Kredit: Silahkan isikan platform untuk mengetahui simulasi kredit yang ingin anda pilih</div>
             <v-col cols="col-12" lg="4">
                 <v-menu
                    ref="date"
                    v-model="date"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="pickedDate"
                        label="Tanggal"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        persistent-hint
                        @blur="date = parseDate(dateFormatted)"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="pickedDate"
                    @input="fromDate = false"
                    >
                    </v-date-picker>
                </v-menu>
                <v-select v-model="selBunga"
                        item-text="text"
                        item-value="value"
                        :items="itemsBunga"
                        @change="onChange"
                        label="Pilih Bunga (Menurun/Tetap)"
                        prepend-icon="mdi-filter"
                        dense>
                        </v-select>
                <v-text-field prepend-icon="mdi-filter" label="Bunga (Otomatis)"
                            v-model="txtBunga"
                            disabled>{{ bunga }}</v-text-field>
                <v-text-field prepend-icon="mdi-filter" label="Rencana Pinjaman"
                            v-model="txtRencanaPinjaman"></v-text-field>
                <!-- <v-select v-model="selBulan"
                        :items="itemsBulan"
                        label="Jangka Waktu (Bulan)"
                        prepend-icon="mdi-filter"
                        dense>
                        </v-select> -->
                <v-text-field prepend-icon="mdi-filter" label="Jangka Waktu (Bulan)"
                            v-model="selBulan"></v-text-field>
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                    class="text-capitalize button-shadow mr-1"
                    @click="simulasi"
                    >Simulasi Kredit</v-btn>
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                    class="text-capitalize button-shadow mr-1"
                    @click="actAjukan"
                    >Ajukan Pinjaman</v-btn>
             </v-col>
        </v-col>
    </v-row>
    <div class="tables-basic" v-show="tableShow">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">No</th>
                        <th class="text-left">Tanggal</th>
                        <th class="text-right">Pokok</th>
                        <th class="text-right">Bunga</th>
                        <th class="text-right">Total Angsuran</th>
                        <th class="text-right">Saldo Akhir</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in dataTable" :key="data.no">
                        <td class="text-left">{{ data.no }}</td>
                        <td class="text-left">{{ data.tgl }}</td>
                        <td class="text-right">{{ data.pokok }}</td>
                        <td class="text-right">{{ data.bunga }}</td>
                        <td class="text-right">{{ data.total }}</td>
                        <td class="text-right">{{ data.saldoakhir }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'PengajuanPinjaman',
  data () {
    return {
        tableShow: false,
        pickedDate: new Date().toISOString().substr(0, 10),
        txtBunga: 0,
        itemsBunga: [
          {value: 0, text: 'Pijaman Koperasi (Bunga Tetap)'},
        //   {value: 1, text: 'Bunga Menurun'}
        ],
        itemsBulan: [
            "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"
        ],
        dataTable:[],
    }
  }, 
    computed: {
        ...mapGetters({
            user : 'auth/user',
        }),
    },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    onChange(){
        let formData = new FormData()
        formData.set('id', this.selBunga)

        this.axios.post('/getBunga', formData)
        .then((response) => {
            this.txtBunga = response.data.data
        })
        .catch((error) => {
            let responses = error.response
            this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
            })
        })
    },
    simulasi(){
        let pokok = 0;
        let bunga = 0;
        let total = 0;
        let jumlahpinjaman = this.txtRencanaPinjaman;
        let saldoakhir = 0;
        let list= [];
        let dt = new Date(this.pickedDate)
        let date;
        let month = dt.getMonth()+1
        let year = dt.getFullYear()

        let now = new Date();

        dt.setHours(now.getHours())
        dt.setMinutes(now.getMinutes())
        dt.setSeconds(now.getSeconds())

        for(let i=1; i <= this.selBulan; i++){
            if(this.selBunga == "0"){
                /** Jika bunga Tetap */
                pokok = this.txtRencanaPinjaman/this.selBulan;
                bunga = (this.txtRencanaPinjaman*(this.txtBunga/100))/this.selBulan;
                total = pokok+bunga;
                saldoakhir = jumlahpinjaman-pokok;
                
                /** Hitung date - start*/
                if(month == 12){
                    month = 1
                    year = year+1
                } else {
                    month = month+1
                }
                
                date = dt.getDate()+"/"+month+"/"+year
                /** Hitung date - end */
            } else if(this.selBunga == "1"){
                /** Jika bunga Menurun */
                pokok = this.txtRencanaPinjaman/this.selBulan;
                bunga = (jumlahpinjaman*(this.txtBunga/100))/this.selBulan;
                total = pokok+bunga;
                saldoakhir = jumlahpinjaman-pokok;

                /** Hitung date - start*/
                if(month == 12){
                    month = 1
                    year = year+1
                } else {
                    month = month+1
                }
                
                date = dt.getDate()+"/"+month+"/"+year
                /** Hitung date - end */
            }

            list.push(
                {
                    "no": i,
                    "tgl": date,
                    "pokok": pokok.toLocaleString(),
                    "bunga": bunga.toLocaleString(),
                    "total": total.toLocaleString(),
                    "saldoakhir": saldoakhir.toLocaleString(),
                }
            );

            jumlahpinjaman = saldoakhir;
        }

        this.dataTable = list;
        this.tableShow = true
    },
    actAjukan(){
        /** formating date */
        let dt = new Date(this.pickedDate)
        let now = new Date()

        dt.setHours(now.getHours(),now.getMinutes(),now.getSeconds())
        /** end */
        // console.log( this.user.pk);

        let formData = new FormData()
        formData.set('id', this.selBunga)
        formData.set('nasabahfk', this.user.id)
        formData.set('date', moment(dt).format("YYYY-MM-DD HH:mm:ss"))
        formData.set('bunga', this.selBunga)
        formData.set('nilaibunga', parseFloat(this.txtBunga) * 12 )
        formData.set('pinjaman', this.txtRencanaPinjaman)
        formData.set('bulan', this.selBulan)
         formData.set('notrs', moment(dt).format("YYMMDDHHmmss"))
        //formData.set('notrs', moment(dt).format("YYMM"))

        this.axios.post('/insertPengajuan', formData)
        .then((response) => {
            if(response.data.status == "success"){
                this.setAlert({
                    status : true,
                    color : 'success',
                    text : response.data.message,
                })

                this.$router.push('daftarpinjaman');
            }
        })
        .catch((error) => {
            let responses = error.response
            this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
            })
        })
    },
  },
}

</script>