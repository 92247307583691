<template>
  <v-container fluid>
    <v-row>
        <h1    class="page-title mt-10 mb-6  ">Profile Singkat Koperasi</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
         
        <p style="text-align: justify;">Koperasi Karyawan Coca-Cola Unit Bali merupakan sebuah lembaga perekonomian yang berada di lingkungan perusahaan Coca-Cola, yang beranggotakan karyawan Coca-Cola. Koperasi Karyawan Coca-Cola didirikan sekitar tahun 1995 dan telah berbadan hukum pada tanggal 17 Februari 1997 denga&nbsp; nomor Badan Hukum 27/BH/KWK/22/II/1997 beralamat di Desa Werdi Bhuwana, Mengwi, Badung. Alamat kantor Jl.Nangka Utara No. 196 Denpasar. Koperasi Karyawan Coca-Cola Unit Bali bergerak di bidang usaha Simpan Pinjam dan Jasa Pengadaan untuk Anggota dan Perusahaan Coca-Cola.</p>
      </v-row>
    </div>
    <br><br>

    <v-row>
        <h1 class="page-title mt-10 mb-6">VISI</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
         <p><strong>Menjadi</strong> <strong>Koperasi</strong><strong> yang </strong><strong>mampu</strong> <strong>memberikan</strong> <strong>nilai</strong> <strong>ekonomi</strong> <strong>untuk</strong> <strong>anggota</strong></p>
      </v-row>
    </div>
    <br><br>

    <v-row>
        <h1 class="page-title mt-10 mb-6">MISI</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
              <li><strong>Memberikan</strong> <strong>Pelayanan</strong><strong> yang </strong><strong>terbaik</strong> <strong>untuk</strong> <strong>Anggota</strong></li>
              <li><strong>Meningkatkan</strong> <strong>nilai</strong> <strong>Investasi</strong> <strong>dari</strong> <strong>Anggota</strong></li>
              <li><strong>Meningkatkat</strong> <strong>Kemampuan</strong> <strong>perekonomian</strong> <strong>Anggota</strong></li>
            </ol>
      </v-row>
    </div>
    <br><br>
     

    
     <v-row>
        <h1 class="page-title mt-10 mb-6">Prinsip dan asas koperasi</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
              <li><strong>Keanggotaan</strong> <strong>bersifat</strong> <strong>Sukarela</strong><strong> dan Terbuka</strong></li>
              <li><strong>Pengelolaan</strong> <strong>dilakukan</strong> <strong>secara</strong> <strong>Demokratis</strong><strong> dan </strong><strong>Profesional</strong></li>
              <li><strong>Pembagian</strong> <strong>Sisa</strong><strong> Hasil Usaha </strong><strong>dilakukan</strong> <strong>secara</strong> <strong>adil</strong> <strong>sebanding</strong> <strong>dengan</strong> <strong>besarnya</strong> <strong>jasa</strong> <strong>usaha</strong> <strong>tiap</strong> <strong>tiap</strong> <strong>anggota</strong></li>
              <li><strong>Melaksanakan</strong><strong> Pendidikan </strong><strong>koperasi</strong></li>
              <li><strong>Melakukan</strong><strong> Kerjasama </strong><strong>dengan</strong> <strong>berbagai</strong> <strong>pihak</strong> <strong>dengan</strong> <strong>prinsip</strong> <strong>keadilan</strong></li>
              <li><strong>Kemandirian</strong></li>
              </ol>
      </v-row>
    </div>
    <br><br>


    <v-row>
        <h1 class="page-title mt-10 mb-6">Tujuan pendirian</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
              <li><strong>Membantu</strong> <strong>anggota</strong> <strong>untuk</strong> <strong>memperoleh</strong> <strong>fasilitas</strong> <strong>pinjaman</strong><strong> yang </strong><strong>mudah</strong><strong> dan </strong><strong>murah</strong></li>
              <li><strong>Membantu</strong> <strong>anggota</strong> <strong>berinvestasi</strong> <strong>untuk</strong><strong> masa </strong><strong>pensiun</strong><strong>.</strong></li>
              <li><strong>Meningkatkan</strong> <strong>kesejahtraan</strong> <strong>anggota</strong></li>
              <li><strong>Menjaga</strong><strong> Gerakan </strong><strong>ekonomi</strong> <strong>rakyat</strong><strong>, </strong><strong>serta</strong> <strong>ikut</strong> <strong>membangun</strong> <strong>tatanan</strong> <strong>perekonomian</strong><strong> Nasional</strong></li>
              </ol>
      </v-row>
    </div>
    <br><br>


    <v-row>
        <h1 class="page-title mt-10 mb-6">Hak dan kewajiban anggota</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
              <li><strong>Mendapatkan</strong> <strong>manfaat</strong><strong> dan </strong><strong>pelayanan</strong> <strong>atas</strong> <strong>seluruh</strong> <strong>kegiatan</strong> <strong>koperasi</strong><strong>.</strong></li>
              <li><strong>Mendapatkan</strong> <strong>Sisa</strong><strong> Hasil Usaha</strong></li>
              <li><strong>Berpartisifasi</strong> <strong>aktif</strong> <strong>terhadap</strong> <strong>seluruh</strong> <strong>kegiatan</strong><strong> dan program </strong><strong>koperasi</strong></li>
              <li><strong>Mentaati</strong> <strong>seluruh</strong> <strong>ketentuan</strong><strong> dan </strong><strong>peraturan</strong><strong> yang </strong><strong>berlaku</strong><strong> di </strong><strong>koperasi</strong></li>
              </ol>
      </v-row>
    </div>
    <br><br>

    <v-row>
        <h1 class="page-title mt-10 mb-6">Keuntungan dan Manfaaat Menjadi Anggota</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
              <li><strong>Anggota</strong> <strong>bisa</strong> <strong>meminjam</strong><strong> uang pada </strong><strong>koperasi</strong> <strong>untuk</strong><strong> modal </strong><strong>usaha</strong><strong> dan </strong><strong>keperluan</strong><strong> lain</strong></li>
              <li><strong>Anggota</strong> <strong>bisa</strong> <strong>membeli</strong> <strong>barang-barang</strong> <strong>rumah</strong> <strong>tangga</strong> <strong>secara</strong> <strong>kredit</strong></li>
              <li><strong>Anggota</strong> <strong>bisa</strong> <strong>menabung</strong><strong> di </strong><strong>koperasi</strong> <strong>dengan</strong> <strong>bunga</strong> <strong>tinggi</strong></li>
              <li><strong>Anggota</strong> <strong>memiliki</strong> <strong>investasi</strong><strong> yang </strong><strong>meningkat</strong></li>
              <li><strong>Ikut</strong> <strong>serta</strong> <strong>dalam</strong> <strong>Rapat</strong> <strong>Anggota</strong> <strong>Tahunan</strong></li>
              </ol>
      </v-row>
    </div>
    <br><br>


    <v-row>
        <h1 class="page-title mt-10 mb-6">KEANGGOTAAN BERAKHIR BILAMANA</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
              <li><strong>Meninggal</strong><strong> Dunia</strong></li>
              <li><strong>Keluar</strong> <strong>sebagai</strong> <strong>Karyawan</strong><strong> Coca-Cola </strong><strong>atas</strong> <strong>permintaan</strong> <strong>sendiri</strong> <strong>atau</strong> <strong>diberhentikan</strong><strong> oleh </strong><strong>perusahaan</strong></li>
              <li><strong>Diberhentikan</strong><strong> oleh </strong><strong>Pengurus</strong> <strong>karena</strong> <strong>melanggar</strong> <strong>ketentuan</strong><strong> dan </strong><strong>peraturan</strong> <strong>Koperasi</strong></li>
              </ol>
      </v-row>
    </div>
    <br><br>


     <v-row>
        <h1 class="page-title mt-10 mb-6">Info Anggota, Pengurus dan Pengawas</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <p>Jumlah Anggota adalah 368 per Desember 2021</p>
      </v-row>
            <v-row>Pengurus:</v-row>
      <v-row>
            <ol>
            <li>Ketua&nbsp; I Ketut Buda&nbsp; 08123813425</li>
            <li>Bendahara&nbsp; I Made Suardika&nbsp; 081338527158</li>
            <li>Skretaris 1&nbsp; I Gst Gd Yuda Aryantika&nbsp; 081238162007</li>
            <li>Sekretaris 2&nbsp; I Made Sumerta&nbsp; 087860276629</li>
            </ol>
      </v-row>
      <v-row>-</v-row>
      <v-row>
            <p>Pengawas:</p>
      </v-row>
      <v-row> 
            <ol>
            <li>Ketua&nbsp; I Made Suwangsa&nbsp; 081999056786</li>
            <li>Anggota&nbsp; I Wayan Rusyanta&nbsp; 087855096111</li>
            </ol>
      </v-row>
    </div>
    <br><br>


    <v-row>
        <h1 class="page-title mt-10 mb-6">INFO PENGELOLA</h1>
    </v-row>
    <div class="tables-basic">
      <v-row>
            <ol>
            <li><strong>Manager&nbsp; Dewa </strong><strong>Gede</strong><strong> Sumitra Putra&nbsp; 08124637087</strong></li>
            <li><strong>Simpan</strong> <strong>pinjam</strong><strong>&nbsp; Ni </strong><strong>Kadek</strong> <strong>Wartini</strong><strong>&nbsp; 085738232764</strong></li>
            <li><strong>Jasa&nbsp; Ni </strong><strong>Kadek</strong> <strong>Pustikawati</strong><strong>&nbsp; 085333559980</strong></li>
            <li><strong>Kasir</strong><strong>&nbsp; Ni Wayan </strong><strong>Sumartini</strong><strong>&nbsp; 082144032685</strong></li>
            <li><strong>Umum</strong><strong>&nbsp; I Nyoman </strong><strong>Suwena</strong><strong>&nbsp; 081337892810</strong></li>
            </ol>
      </v-row>
    </div>
    <br><br>


  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Profile',
  data () {
    return {

        row: '',
        isShowing:false,
        dataTable:[],
        dataPinjaman: [],
        dataPembayaran: [],
        stsadmin:'',
    }
  },
   

  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    detail(notrs){
        let formData = {
            'notrs' : notrs,
            'tgl': new Date().toISOString().substr(0, 10),
        }

        this.axios.post('/listTPeminjamanByNotrs', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPinjaman = data;
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
        this.axios.post('/listHistoryPembayaran', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPembayaran = data;
                this.isShowing = true
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
    },
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      'tgl': new Date().toISOString().substr(0, 10),
      // 'row': this.row ,
    }

    this.axios.post('/listTPeminjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>