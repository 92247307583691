<template>
  <v-container fluid>
    <v-row>
        <h5 class="page-title mt-8 mb-4">Daftar Tagihan Bulanan</h5>
         
    </v-row>
    <v-row>
      <v-col
      cols="11"
      sm="3"
      >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
      cols="11"
      sm="3"
      >
        <v-btn
        depressed
        color="primary"
        @click="filter()"
      >
        Filter
      </v-btn>
      </v-col>
  
    </v-row>
    <center><h5 class="">Tabungan</h5></center>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">No</th>                        
                        <th class="text-left">No Rekening</th>
                        <th class="text-left">Jenis Tabungan</th>
                        <th class="text-right">Setoran kolektif</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">
                        <td>{{ index+1 }}</td>
                        <td>{{ data.notabungan }}</td>
                        <td>{{ data.nmtabungan }}</td>
                        <td class="text-right">{{ Number(data.setorankolektif).toLocaleString() }}</td>
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>
    <div class="tables-basic history"  >
        <center><h5 class="">Pinjaman</h5></center>

        <v-card class="mb-1">
        <v-simple-table>
            <thead>
                <tr>
                    <th class="text-center">No</th>
                    <th class="text-left">No Pinjaman</th>                        
                    <th class="text-left">Jenis Pinjaman</th>
                    <th class="text-right">Pokok</th>
                    <th class="text-right">Bunga</th>
                    <th class="text-right">Total Potongan</th>
                     
                </tr>
            </thead>
            
            <tbody>
                <tr v-for="(data, index) in dataPembayaran" :key="data.pk">
                    <td>{{ index+1 }}</td>
                    <td>{{ data.notrs }}</td>
                    <td>{{ data.nm }}</td>
                    <td class="text-right">{{Number(data.totalpeminjaman/data.lamapinjam).toLocaleString()}}  </td>
                    <td class="text-right">{{ Number((data.totalpeminjaman) * (data.bungapinjam/1200)).toLocaleString() }}</td>
                    <td class="text-right">{{ Number(data.jumlahangsuran).toLocaleString() }}</td>
                </tr>
            </tbody>
        </v-simple-table>
        </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'daftartagihan',
  data () {
    return {
        row: '',
        isShowing:false,
        dataTable:[],
        dataPembayaran: [],
         date: new Date().toISOString().substr(0, 7),  
         thn:new Date().toISOString().substr(0,4),
         bln:new Date().toISOString().substr(5,2),
        menu: false,
        modal: false,
    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    filter(){
      let formData = {
      'id' : this.user.id,
      'periode': this.date,
      'thn':this.date.substr(0,4),
      'bln':this.date.substr(5,2),
      // 'row': this.row ,
    }
      console.log (this.date);
      this.axios.post('/tagihanTabungan', formData)
      .then((response) => {
        // console.log ('bln :', this.bln);
        let { data } = response.data
        this.dataTable = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })


      this.axios.post('/tagihanPinjaman', formData)
      .then((response) => {
        let { data } = response.data
        this.dataPembayaran = data;
      })
      .catch((error) => {
        let responses = error.response
        this.setAlert({
          status : true,
          color : 'error',
          text : responses.data.message,
        })
      })
      
    },
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      'periode': this.date,
      'bln':this.bln,
      'thn':this.thn,
      // 'row': this.row ,
      
    }

    this.axios.post('/tagihanTabungan', formData)
    .then((response) => {
      console.log ('bln :', this.thn);
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })

    this.axios.post('/tagihanPinjaman', formData)
    .then((response) => {
      let { data } = response.data
      this.dataPembayaran = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })

  }
}

</script>