<template>
    <v-app class="pa-6">
        <Header />
        <Sidebar />
        <alert />
        <v-main class="content">
            <router-view />
            <!-- <Footer /> -->
        </v-main>
    </v-app>
</template>

<script>
    import Header from '@/components/Header/Header';
    import Sidebar from '@/components/Sidebar/Sidebar';
    // import Footer from "@/components/Footer/Footer";
    import Alert from "@/components/Alert";
    import './Layout.scss';

    export default {
        name: 'Layout',
        components: {Header, Sidebar, Alert },
    };
</script>