<template>
  <v-container fluid>
    <v-row>
        <h5 class="page-title mt-8 mb-4">Edit Profile</h5>
    </v-row>
    <form>
      <v-col cols="12" sm="4" >
        <v-text-field 
           
          v-model="name"        
          :rules="[() => !!name || 'Nama tidak boleh kosong']"
          label="Nama"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" >
        <v-text-field
          v-model="alamat"
          label="Alamat"
           :rules="[() => !!alamat || 'Alamat tidak boleh kosong']"
          required
          
        ></v-text-field>
      </v-col>
       <v-col cols="12" sm="4" >
        <v-text-field
          v-model="notlp"
          label="No Hp/WA"
           :rules="[() => !!notlp || 'No Hp/WA tidak boleh kosong'
          ]"
          required
        ></v-text-field>
      </v-col>
      <!-- <v-divider class="mx-4"></v-divider>
      <v-card-title>Edit Password Login</v-card-title> -->

      <v-col cols="12" sm="4" >
        <v-text-field
          v-model="pass"
          label="Password"
          type="password"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" >
        <v-text-field
          v-model="repassword"
          type="password"
           :rules="[passwordConfirmationRule]"
          label="Re-Password"
          required
        ></v-text-field>
      </v-col>
      

      <v-btn
        class="mr-4"
        @click="simpanprofile"
      >
        submit
      </v-btn>
      <v-btn @click="gotodashboard">
        Cancel
      </v-btn>
    </form>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import md5 from 'js-md5';
export default {
  name: 'daftartagihan',
  data () {
    return {
      datanasabah: [],
       
        pass:'',
        repassword:'',
        name:'',
        alamat:'',
        notlp:'',
      
        

    }
  },
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
    passwordConfirmationRule() {
        return () => (this.pass === this.repassword) || 'Password must match'
    },

  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    gotodashboard(){
       this.$router.push('/dashboard');
    },
    simpanprofile(){
      console.log( 'simpan ',this.name);
      const fd=new FormData();

      fd.append('pk',this.user.id);
      fd.append('nm',this.name);
      fd.append('alamat',this.alamat);
      fd.append('tlp',this.notlp);
      if (this.pass) {
        fd.append('password',md5(this.pass));
      }else{
        fd.append('password',this.pass);
      }
      
       

      this.axios.post('/upateProfile', fd)
        .then((response) => {
            if(response.data.status == "success"){
                this.setAlert({
                    status : true,
                    color : 'success',
                    text : response.data.message,
                })

                this.$router.push('dashboard');
            }
        })
        .catch((error) => {
            let responses = error.response
            this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
            })
        })

    },
     
    
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      // 'row': this.row ,
    }

    this.axios.post('/loadProfile', formData)
    .then((response) => {
      let { data } = response.data
      this.datanasabah = data;
      this.name=this.datanasabah[0].nm;
      this.alamat=this.datanasabah[0].alamat;
      this.notlp=this.datanasabah[0].tlp;
      // console.log ('Nama' ,this.name);


    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })

    

  }
}

</script>