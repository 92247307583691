var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"tables-basic"},[_c('h1',{staticClass:"page-title mt-10 mb-6"},[_vm._v("Mutasi Simpanan")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-1"},[_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"col-12","lg":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"col-12","lg":"12"}},[_c('v-select',{attrs:{"item-text":"notabungan","item-value":"notabungan","items":_vm.listNamatabungan,"label":"Pilih Simpanan","prepend-icon":"mdi-filter","dense":""},model:{value:(_vm.selNametabungan),callback:function ($$v) {_vm.selNametabungan=$$v},expression:"selNametabungan"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"col-12","lg":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"col-12","lg":"6"}},[_c('v-menu',{ref:"fromDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal","prepend-icon":"mdi-calendar","readonly":"","persistent-hint":""},model:{value:(_vm.fromPickedDate),callback:function ($$v) {_vm.fromPickedDate=$$v},expression:"fromPickedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.fromDate = false}},model:{value:(_vm.fromPickedDate),callback:function ($$v) {_vm.fromPickedDate=$$v},expression:"fromPickedDate"}})],1)],1),_c('v-col',{attrs:{"cols":"col-12","lg":"6"}},[_c('v-menu',{ref:"toDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Sampai","prepend-icon":"mdi-calendar","readonly":"","persistent-hint":""},model:{value:(_vm.toPickedDate),callback:function ($$v) {_vm.toPickedDate=$$v},expression:"toPickedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('v-date-picker',{on:{"input":function($event){_vm.toDate = false}},model:{value:(_vm.toPickedDate),callback:function ($$v) {_vm.toPickedDate=$$v},expression:"toPickedDate"}})],1)],1)],1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"col-12","lg":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize button-shadow mr-1",attrs:{"color":"primary"},on:{"click":_vm.filter}},'v-btn',attrs,false),on),[_vm._v("Filter")])]}}])})],1)],1)],1)],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableShow),expression:"tableShow"}]},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left pa-6"},[_vm._v("Tanggal")]),_c('th',{staticClass:"text-left"},[_vm._v("Keterangan Rek")]),_c('th',{staticClass:"text-right"},[_vm._v("Debet")]),_c('th',{staticClass:"text-right"},[_vm._v("Kredit")]),_c('th',{staticClass:"text-right"},[_vm._v("Saldo Akhir")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(_vm.fromPickedDate))]),_c('td',{staticClass:"text-left"},[_vm._v("Saldo Awal")]),_c('td',{staticClass:"text-right"},[_vm._v("0")]),_c('td',{staticClass:"text-right"},[_vm._v("0")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(Number(Math.round(_vm.saldoAwal)).toLocaleString()))])]),_vm._l((_vm.dataTableSimpanan),function(data){return _c('tr',{key:data.pk},[_c('td',{staticClass:"pa-6"},[_vm._v(_vm._s(data.tgl))]),_c('td',[_vm._v(_vm._s(data.nm))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(Number(Math.round(data.debet)).toLocaleString()))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(Number(Math.round(data.kredit)).toLocaleString()))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(Number(Math.round(data.saldo)).toLocaleString()))])])})],2)]},proxy:true}])})],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }