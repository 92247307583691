<template>
  <v-container fluid>
    <div class="tables-basic">
      <h1 class="page-title mt-10 mb-6">Data Simpanan</h1>
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left pa-6">No Rek.</th>
                    <th class="text-left pa-6">Nama Simpanan</th>
                    <th class="text-left">Tgl Buka Rek</th>
                    <!-- <th class="text-right">Bunga</th> -->
                    <th class="text-right">Saldo Akhir</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="sim in simpanan" :key="sim.notabungan">
                      <td class="pa-6">{{ sim.notabungan }}</td>
                      <td class="pa-6">{{ sim.nmtabungan }}</td>
                      <td>{{ sim.tgl }}</td>
                      <!-- <td class="text-right">{{ Number(sim.sukubunga).toLocaleString() }}</td> -->
                      <td class="text-right">{{ Number(Math.round(sim.saldoakhir-sim.saldotambahan)).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DataSimpanan',
  data: () => ({
    simpanan: []
  }),
  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      'tgl': new Date().toISOString().substr(0, 10),
      'bln' : new Date().getMonth() + 1,
      'thn' : new Date().getFullYear()
    }

    this.axios.post('/getSimpan', formData)
    .then((response) => {
      let { data } = response.data
      this.simpanan = data;
      console.log(this.simpanan);
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>