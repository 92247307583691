<template>
  <v-container fluid>
    <v-row>
        <h1 class="page-title mt-10 mb-6">List Pengumuman/Berita</h1>

    </v-row>
    <v-row>
      

      <v-col cols="auto">
              <v-dialog
                v-model="dialog"
                transition="dialog-top-transition"
                max-width="1000"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >Tambah</v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                    >Tambah Data Berita</v-toolbar>
                    <v-card-text>
                       
                      <v-text-field  label="Judul" 
                            v-model="judul"></v-text-field>

                     <quill-editor
                        ref="myQuillEditor"
                        v-model="content"
                        :options="editorOption"
                        @blur="onEditorBlur($event)"
                        @focus="onEditorFocus($event)"
                        @ready="onEditorReady($event)"
                      />

                       


                    </v-card-text>
                    



                    <v-card-actions class="justify-end">
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="success"
                        @click="simpanberita"
                      >Simpan</v-btn>
                    

                      <v-btn
                        text
                        color="warning"
                        @click="dialog.value = false"
                      >Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
        </v-col>

            
    </v-row>
    <div class="tables-basic">
      <v-row>
        <v-col cols=12>
          <v-card class="mb-1">
            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-center">Aksi</th>
                        <th class="text-left">No</th>         
                        <th class="text-left">Judul</th>               
                        <th class="text-left">Deskripsi</th> 
                        <th class="text-left">Tgl Buat</th>
                        <!-- <th class="text-center">Deskripsi</th>
                        <th class="text-right">Gambar</th>
                        <th class="text-right">File</th> -->
                         
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in dataTable" :key="data.notrs">
                        <td>
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                                class="button-shadow mr-1"
                                @click="detailberita(data.notrs)"
                              >
                                Detail</v-btn>

                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="warning"
                                class="button-shadow mr-1"
                                @click="deletekonfirm(data.notrs)"
                              >
                                Hapus</v-btn>
                        </td>
                        <td>{{ index+1 }}</td>
                        <td><div v-html="data.kop.slice(0, 40)"></div>  </td>
                        <td><div v-html="data.detail.slice(0, 50)"></div></td>
                        <td>{{ data.tanggal }}</td>
                        
                    </tr>
                </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br><br>

    
      <v-col cols="auto">
              <v-dialog
                v-model="showdetailberita"
                transition="dialog-top-transition"
                max-width="1000"
              >
                 
                <template v-slot:default="showdetailberita">
                  <v-card>
                    <v-toolbar
                      color="primary"
                      dark
                    >Detail Berita</v-toolbar>
                    <v-card-text>
                       <div>
                         <h1 class="news-title mt-10 mb-6"  > {{kop}} 
                       </h1> </div>

                         

                      
                      <div v-html="isi"> </div>
                          

            
                    </v-card-text>
                    



                    <v-card-actions class="justify-end">
                       
                    

                      <v-btn
                        text
                        color="warning"
                        @click="showdetailberita.value=false "
                      >Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
        </v-col>


        <v-dialog
            v-model="dialogtanya"
            max-width="290"
          >
            <v-card>
              <v-card-title class="text-h5">
                Hapus Berita
              </v-card-title>

              <v-card-text>
                 Anda yakin menghapus berita ini?
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="green darken-1"
                  text
                  @click="dialogtanya = false "
                >
                  Tidak
                </v-btn>

                <v-btn
                  color="green darken-1"
                  text
                  @click="hapus"
                >
                  Ya
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
 

export default {
  name: 'Berita',
  data () {
    return {
      dialog:false,
      dialogtanya:false,
      kop:'',
      isi:'',
      judul:'',
      pk:'',
      // dialog : false,
      content: '',
        editorOption: {
          // Some Quill options... 
        }    ,
        row: '',
        isShowing:false,
        dataTable:[],
        dataPinjaman: [],
        databerita: [],
        hasil:[],
        showdetailberita:false,
        
        stsadmin:'',
    }
  },
   

  computed: {
    ...mapGetters({
          user : 'auth/user',
    }),
  },
  components: {
    quillEditor
  },
  methods: {
    ...mapActions({
        setAlert : 'alert/set',
        setAuth : 'auth/set',
    }),
    status(n){
        if(n == 1) return "Pengajuan"
        if(n == 2) return "Persetujuan"
        if(n == 3) return "Disetujui"
        if(n == 4) return "Pembayaran Kredit"
        if(n == 5) return "Macet"
        if(n == 6) return "Lunas"
    },
    hidenberita(){
      this.showdetailberita=false;
    },

    refreshberita(){
      let formData = {
        'pk': this.notrs,
      }
        this.axios.post('/listNews', formData)
          .then((response) => {
            let { data } = response.data
            this.dataTable = data;
          })
          .catch((error) => {
            let responses = error.response
            this.setAlert({
              status : true,
              color : 'error',
              text : responses.data.message,
            })
          })
    },
    deletekonfirm(notrs){
      console.log('pk ny ' + notrs)
      this.pk=notrs;
      console.log('pk ny ' + this.pk)
      this.dialogtanya=true
    },

    hapus( ){
      let formData = {
            'pk' : this.pk,
        }
      
      this.axios.post('/hapusberita', formData)
        .then((response) => {
                let { data } = response.data
                this.dataPinjaman = data;
                this.dialogtanya=false;
                this.refreshberita();
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })

    },
    simpanberita(){
      let formData={
        'judul': this.judul,
        'detail1' : this.content,
        'tgl': new Date().toISOString().substr(0, 10),

      }
      
      this.axios.post('/simpandataberita',formData)
      .then((response)=>{
        if(response.data.status == "success"){
                this.content=''
                this.judul=''
                
                this.setAlert({
                    status : true,
                    color : 'success',
                    text : response.data.message,
                }),
                this.dialog = false
                this.refreshberita

            }
        })
        .catch((error) => {
            let responses = error.response
            this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
            })
        })

   
        this.axios.post('/listNews', formData)
        .then((response) => {
          let { data } = response.data
          this.dataTable = data;
        })
        .catch((error) => {
          let responses = error.response
          this.setAlert({
            status : true,
            color : 'error',
            text : responses.data.message,
          })
        })

    },
    
    
    detailberita(notrs){
        let formData = {
            'pk' : notrs,
        }
        
        
        this.axios.post('/detailberita', formData)
        .then((response) => {
                let { data } = response.data
                this.databerita = data;
                this.kop=this.databerita[0].kop;
                this.isi=this.databerita[0].detail;
            })
            .catch((error) => {
            let responses = error.response
            this.setAlert({
                status : true,
                color : 'error',
                text : responses.data.message,
            })
        })
        this.showdetailberita=true;
         
    },
  },
  mounted: function() {
    let formData = {
      'id' : this.user.id,
      'tgl': new Date().toISOString().substr(0, 10),
      // 'row': this.row ,
    }

    this.axios.post('/listNews', formData)
    .then((response) => {
      let { data } = response.data
      this.dataTable = data;
    })
    .catch((error) => {
      let responses = error.response
      this.setAlert({
        status : true,
        color : 'error',
        text : responses.data.message,
      })
    })
  }
}

</script>